import { toast } from "vue3-toastify";

export const copyToClipboard = (text) => {
  const { copy } = useClipboard();

  copy(text).then(() => {
    toast.success(_T("@Copied to clipboard"), {
      autoClose: 1000,
    });
  });
};
